.heroBlock {
  margin-top: 65px;
  margin-bottom: 85px;

  h1 {
    margin-bottom: 22px;
  }

  p {
    max-width: 750px;
    margin-bottom: 25px;
  }

  @media (max-width: 768px) {
    margin-top: 25px;
    margin-bottom: 0px;
  }
}

.sections {
  margin-top: 60px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;

  .section {
    height: auto;

    &:first-child {
      grid-column: 1 / 2;
      grid-row: 1 / 3;
      display: flex;
      flex-direction: column;

      .textContentPart {
        flex: 1;
      }

      .descriptionContentPart {
        p {
          text-indent: 23px;
          margin-bottom: 25px;

          &:last-child {
            margin-bottom: 30px;
          }

          span {
            position: relative;
            font-weight: 600;

            svg {
              position: absolute;
              left: -23px;
              top: -1px;
              width: 17px;
              height: 17px;
            }
          }
        }
      }

      @media (max-width: 992px) {
        grid-column: 1 / 3;
        flex-direction: row;

        section, figure {
          width: 50%;
          height: 100%;
        }

        img {
          height: 100%;
          object-fit: cover;
        }

        section {
          padding-right: 15px;
        }
        figure {
          padding-left: 15px;
        }
      }

      @media (max-width: 768px) {
        flex-direction: column;

        section, figure {
          width: 100%;
          padding: 0;
        }
      }
    }

    h2 {
      margin-bottom: 22px;
    }

    img {
      border-radius: 15px;
      width: 100%;
      height: 270px;
      object-fit: fill;
    }

    @media (max-width: 768px) {
      grid-column: 1 / 3;
    }
  }

  @media (max-width: 768px) {
    margin-top: 45px;
    gap: 20px;
  }
}
